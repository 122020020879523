import "core-js";
import "whatwg-fetch";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app";
import { BrowserRouter } from "react-router-dom";


// Styling
import "./../scss/style.scss";

// Redux
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import { appReducer } from "./app-reducer";
import { timelineReducer } from "./general-components/content-base/paragraphs/timeline/reducer-timeline";
import { kalenderMonthReducer } from "./general-components/content-base/paragraphs/kalender-monat/reducer-kalender-month";
import {
  authenticationLoginAction,
  authenticationReducer
} from "./intern/redux-authentication";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

// GraphQL
import { restHost, graphQlEndpoint } from "./config";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import possibleTypes from "./../../public/data/possibleTypes.json";

const rootReducer = combineReducers({
    appStore: appReducer,
    timeLine: timelineReducer,
    kalenderMonth: kalenderMonthReducer,
    auth: authenticationReducer,
  }),
  persistedReducer = persistReducer(
    {
      key: "root",
      storage,
      whitelist: ["auth"],
      stateReconciler: autoMergeLevel2,
    },
    rootReducer
  ),
  store = createStore(persistedReducer, window.__PRELOADED_STATE__),
  // @see https://www.apollographql.com/docs/react/migrating/boost-migration/
  authLink = setContext((_, { headers }) => {
    // Get the authentication token from local storage if it exists.
    const storeState = store.getState(),
      token = storeState.auth.userLoginData.access_token;

    if (
      storeState.auth.userLoginData.access_token &&
      storeState.auth.userLoginData.jwt.exp <
        Math.round(new Date().getTime() / 1000)
    ) {
      console.log("RESET LOGIN CLIENT AUTHLINK");
      store.dispatch(authenticationLoginAction({}));
    }

    // Return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        // Get from store.
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }),
  errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),
  client = new ApolloClient({
    link: authLink.concat(
      from([
        errorLink,
        createHttpLink({
          uri: `${restHost}${graphQlEndpoint}`,
          credentials: "same-origin",
        }),
      ])
    ),
    cache: new InMemoryCache({ possibleTypes }).restore(
      window.__APOLLO_STATE__
    ),
  });

// Allow the passed states to be garbage-collected
delete window.__PRELOADED_STATE__;

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <BrowserRouter forceRefresh={false}>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ApolloProvider>
);
